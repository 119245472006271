<template>
  <div
    class="container maintenancePlanInspect"
    v-title
    :data-title="$t('i18n.maintenancePlanInspect')"
  >
    <div id="outer-title">{{ $t("i18n.maintenancePlanInspect") }}</div>
    <div v-loading="loading">
      <jl-table
        :tableData="tableData"
        @init="init"
        :options="options"
        :columns="columns"
        :operates="operates"
        :total="total"
        :height="$defined.HEIGHT - 360 + 'px'"
      >
      </jl-table>
    </div>
    <el-dialog
      :title="$t('i18n.updateInspectStatus')"
      v-model="dialogVisible"
      :close-on-click-modal="false"
      destroy-on-close
    >
      <jl-form
        :columns="inspectColumns"
        @onSubmit="onSubmit"
        @resetForm="resetForm"
      ></jl-form>
    </el-dialog>
  </div>
</template>
<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import jlTable from "../../components/table";
import jlForm from "../../components/form";
export default {
  name: "MaintenancePlanInspect",
  components: {
    jlTable,
    jlForm,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const router = useRouter();
    const state = reactive({
      formInline: {
        pageNumber: 1,
        pageSize: 10,
      },
      maintainBillModel: [],
      // table数据
      tableData: [],
      options: {
        // table 的参数
        isAdd: false, // 是否启用新增功能
        isEdit: false, // 是否启用编辑功能
        isDelete: false, // 是否启用删除功能
        highlightCurrentRow: false, // 是否支持当前行高亮显示
        multiSelect: false, // 是否支持列表项选中功能
        pageShow: true, // 是否翻页
      },
      columns: [
        // 需要展示的列
        {
          prop: "elevatorId",
          label: "elevatorNo",
          search: true,
          rows: false,
          type: "remotes",
          props: { label: "number", value: "id" },
          loading: false,
          data: [],
          remoteMethod: (val) => {
            remoteEleMethod(val);
          },
        },
        {
          prop: "year",
          label: "year",
          search: true,
          rows: false,
          type: "year",
        },
        {
          prop: "upkeepContractNumber",
          label: "maintenanceContractNo",
          align: "center",
          formatter: (row) => {
            return row.upkeepType
              ? `<a>${row.upkeepContractNumber}</a>`
              : `<span>${row.upkeepContractNumber}</span>`;
          },
          method: (row) => {
            let page = row.upkeepType == 10 ? "Viewupdate" : "Viewcou";
            if (row.upkeepType) {
              router.push({
                path: "/upkeepContract/upkeepContract" + page,
                query: { id: row.upkeepContractId },
              });
            }
          },
        },
        {
          prop: "maintenanceMan",
          label: "maintenancePerson",
          align: "center",
        },
        {
          prop: "planTime",
          label: "startTime",
          align: "center",
        },
        {
          prop: "planEndTime",
          label: "endDate",
          align: "center",
        },
        {
          prop: "planType",
          label: "orderType",
          align: "center",
          formatter: (row) => {
            if (row.planType == null) {
              return "-";
            }
            let current = state.maintainBillModel.filter((item) => {
              return item.value == row.planType;
            });
            return t("i18n." + current[0].code);
          },
        },
        {
          prop: "isInspect",
          label: "inspectStatus",
          type: "select",
          search: true,
          rows: false,
          props: { label: "locale", value: "value" },
          data: [
            { locale: "EXAMINED", value: true },
            { locale: "PENDING", value: false },
          ],
        },
        {
          prop: "inspectResult",
          label: "inspectStatus",
          align: "center",
          formatter: (row) => {
            if (row.inspectResult === null) {
              return `<a>${t("i18n.PENDING")}</span>`;
            } else {
              return `<a style="color: OrangeRed">${t("i18n.EXAMINED")}</a>`;
            }
          },
          method: (row) => {
            updateInspect(row);
          },
        },
      ],
      operates: {
        // 列操作按钮
        width: 200,
        fixed: "right",
        list: [],
      },
      total: 0,
      loading: true,
      dialogVisible: false,
      inspectColumns: [
        {
          prop: "id",
          label: "",
          show: false,
        },
        {
          prop: "inspectResult",
          label: "inspectStatus",
          type: "input",
          readonly: true,
        },
      ],
    });

    const getMaintainBillModel = () => {
      proxy.$api.common.getLineageByCode("plan_type").then((res) => {
        state.maintainBillModel = res.data.lineages;
      });
    };
    getMaintainBillModel();

    const init = async (params) => {
      state.loading = true;
      params.year = proxy.$defined.format(params.year, "yyyy");
      const { data } = await proxy.$api.maintenance.maintenancePlanInspect(
        params
      );
      state.tableData = data.records;
      state.total = data.total;
      state.loading = false;
    };

    const updateInspect = (row) => {
      state.dialogVisible = true;
      state.inspectColumns.map((item) => {
        item.isUpdate = true;
        item.value = row[item.prop];
        item.readonly = row.inspectResult !== null;
      });
    };

    const onSubmit = async (form) => {
      form.result = form.inspectResult;
      form.planId = form.id;
      await proxy.$api.maintenance.updatenspect(form);
      resetForm();
      init(state.formInline);
      proxy.$defined.tip(t("i18n.edit") + t("i18n.success"), "success");
    };

    const resetForm = () => {
      state.dialogVisible = false;
    };

    const remoteEleMethod = (val) => {
      var index = state.columns.findIndex((item) => {
        return item.prop === "elevatorId";
      });
      if (val) {
        state.columns[index].loading = true;
        setTimeout(() => {
          proxy.$api.maintenance
            .getAllEle({ elevatorNumber: val })
            .then((data) => {
              state.columns[index].data = data.data;
              state.columns[index].loading = false;
            });
        }, 1000);
      } else {
        state.columns[index].data = [];
      }
    };

    return {
      ...toRefs(state),
      updateInspect,
      init,
      onSubmit,
      resetForm,
      remoteEleMethod,
    };
  },
};
</script>

<style lang="scss">
.maintenancePlanInspect {
  .el-form-inline {
    .el-input {
      line-height: unset;
    }
  }
}
</style>
